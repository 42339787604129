function microModalSet() {
    return typeof MicroModal !== 'undefined';
}

function init() {
    if ( microModalSet() ) {
        MicroModal.init();
    }

    const btns = document.querySelectorAll('.js-link-to-popup');

    for (let btn of btns) {
        btn.addEventListener('click', (ev) => {
            ev.preventDefault();
            const popupId = btn.dataset.popup;
            showPopup(popupId);
        })
    }
}

function showPopup(id) {
    if ( microModalSet() ) {
        MicroModal.show(id);
    }
}

function closePopup(id) {
    if ( microModalSet() ) {
        MicroModal.close(id);
    }
}

export default {
    init,
    showPopup,
    closePopup
}
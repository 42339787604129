const balloonElems = document.querySelectorAll('.g-balloon');

function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
}

function handleClickBalloon() {
    for ( let balloonElem of balloonElems ) {
        balloonElem.addEventListener('click', (ev) => {
            const mouseX = ev.clientX;
            const mouseY = ev.clientY;
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;
            const posX = mouseX / windowWidth;
            const posY = mouseY / windowHeight;

            confetti({
                particleCount: 100,
                spread: 70,
                origin: {
                    x: posX,
                    y: posY
                }
            });

            const popupIndex = parseInt(ev.currentTarget.dataset.number) - 1;
            window.setTimeout(() => {
                location.assign(amLandingLinks[popupIndex])
            }, 1100)
        });
    }
}

export default function () {
    if ( !document.body.matches('.page-template-page_landing') && !document.body.matches('.page-template-page_landing-mobile') ) {
        return;
    }
    handleClickBalloon();
}